/*--------------------------------------------------------------
# Header1
--------------------------------------------------------------*/
#header1 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9997;
  transition: all 0.5s;
  padding: 15px;
  overflow-y: auto;
}

@media (max-width: 992px) {
  #header1 {
    width: 300px;
    background: #fff;
    border-right: 1px solid #e6e9ec;
    left: -300px;
  }
}
/*--------------------------------------------------------------
# home Section
--------------------------------------------------------------*/
#home {
    /* width: 100%; */
    height: 100vh;
    background: url("../component/image/croped.webp") top right no-repeat;
    /* background: url("./image/croped.webp") top right no-repeat; */
    background-size: cover;
    position: relative;
}

@media (min-width: 992px) {
    #home {
        padding-left: 160px;
    }
}

#home:before {
    content: "";
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#home h1 {
    margin: 0;
    font-size: 100px;
    font-weight: 700;
    line-height: 56px;
    color: #45505b;
}

#home p {
    color: #45505b;
    margin: 25px 0 0 0;
    font-size: 45px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

#home p span {
    color: #4caf50;
    letter-spacing: 1px;
}

#home .social-links {
    margin-top: 25px;
}

#home .social-links a {
    font-size: 24px;
    display: inline-block;
    color: #455b46;
    line-height: 1;
    margin-right: 20px;
    transition: 0.3s;
}

#home .social-links a:hover {
    color: #4caf50;
}

@media (max-width: 992px) {
    #home {
        text-align: center;
    }

    #home h1 {
        font-size: 32px;
        line-height: 36px;
    }

    #home p {
        margin-top: 10px;
        font-size: 20px;
        line-height: 24px;
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.nav-menu {
    padding: 0;
    display: block;
  }
  .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
  }
  .nav-menu a, .nav-menu a:focus {
    display: flex;
    align-items: center;
    color: #45505b;
    padding: 10px 18px;
    margin-bottom: 8px;
    transition: 0.3s;
    font-size: 15px;
    border-radius: 50px;
    background: #f2f3f5;
    height: 60px;
    width: 100%;
    overflow: hidden;
    transition: 0.3s;
  }
  .nav-menu a i, .nav-menu a:focus i {
    font-size: 20px;
  }
  .nav-menu a span, .nav-menu a:focus span {
    padding: 0 5px 0 7px;
    color: #45505b;
  }
  @media (min-width: 992px) {
    .nav-menu a, .nav-menu a:focus {
      width: 60px;
    }
    .nav-menu a span, .nav-menu a:focus span {
      display: none;
      color: #fff;
    }
  }
  .nav-menu a:hover, .nav-menu .active, .nav-menu .active:focus, .nav-menu li:hover > a {
    color: #fff;
    background: #4caf50;
    border-radius: 50px;
  }
  .nav-menu a:hover span, .nav-menu .active span, .nav-menu .active:focus span, .nav-menu li:hover > a span {
    color: #fff;
    border-radius: 50px;
  }
  .nav-menu a:hover, .nav-menu li:hover > a {
    width: 100%;
    color: #fff;
    border-radius: 50px;
    position: relative; 
  }
  .nav-menu a:hover span, .nav-menu li:hover > a span {
    border-radius: 50px;
    display: block;
  }
  
  *, ::after, ::before {
    box-sizing: border-box;
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    background: #d7dce1;
    color: #33363a;
    font-size: 14px;
    text-align: center;
    padding: 30px 0;
    margin-top: 4rem;
  }
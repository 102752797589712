/*--------------------------------------------------------------
# Header1
--------------------------------------------------------------*/
#blog-header {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9997;
  transition: all 0.5s;
  padding: 30px;
  overflow-y: auto;
}



/*--------------------------------------------------------------
 # Navigation Menu
 --------------------------------------------------------------*/
/**
 * Desktop Navigation 
 */
.nav-menu {
  padding: 0;
  display: block;
}

.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu>ul>li {
  position: relative;
  white-space: nowrap;
}

.nav-menu a,
.nav-menu a:focus {
  display: flex;
  align-items: center;
  color: #45505b;
  padding: 10px 18px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 15px;
  border-radius: 50px;
  background: #f2f3f5;
  height: 60px;
  width: 100%;
  overflow: hidden;
  transition: 0.3s;
}

.nav-menu a span,
.nav-menu a:focus span {
  padding: 0 5px 0 7px;
  color: #45505b;
}

@media (min-width: 992px) {

  .nav-menu a,
  .nav-menu a:focus {
    width: 60px;
  }

  .nav-menu a span,
  .nav-menu a:focus span {
    display: none;
    color: #fff;
  }
}


*,
::after,
::before {
  box-sizing: border-box;
}